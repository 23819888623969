import React, { Component } from "react";
import Papa from "papaparse";
import axios from "axios";

//set date
const currentDate = new Date();
const nextYearDate = new Date(currentDate);
nextYearDate.setFullYear(currentDate.getFullYear() + 1);

const monthToNumber = (month) => {
  const monthMap = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };

  return monthMap[month];
};

const processAddress = (strVal) => {
  if (strVal.includes(",")) {
    const [address, city] = strVal.split(",");
    return [address, city];
  }
  return [strVal];
};

function processSalary(str) {
  // Remove commas
  let result = str.replace(/,/g, "");

  // Remove the trailing slash
  result = result.replace(/\/-$/, "");

  // Convert to a number
  return parseInt(result, 10);
}

export default class MassUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      hasError: false,
      errMsg: "",
      completed: false,
      departments: [],
    };
  }
  componentDidMount() {
    axios({
      method: "get",
      url: "/api/departments",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ departments: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ hasError: false, errorMsg: "", completed: false });
    if (this.state.data.length === 0) {
      this.setState({
        hasError: true,
        errMsg: "0 entries made",
      });
      window.scrollTo(0, 0);
      return;
    }

    for (let i = 0; i < this.state.data.length; i++) {
      const userData = this.state.data[i];
      console.log(userData);
      const addressArr = processAddress(userData.address);
      let city = "Kathmandu";
      if (addressArr.length > 1) {
        city = addressArr[1];
      }
      let address = addressArr[0];

      const filteredDepartments = this.state.departments.filter(
        (department) => department.name == userData.department
      );

      console.log(this.state.departments[0].id);

      const user = {
        email: userData.email,
        password: "secret",
        fullname: userData.fullName,
        role: "ROLE_EMPLOYEE",
        departmentId: this.state.departments[0]?.id || "1",
        active: 1,
        jobTitle: userData.jobTitle,
        startDate: userData.startDate,
        endDate: nextYearDate,
      };

      try {
        // Creating user
        const userResponse = await axios({
          method: "post",
          url: "/api/users",
          data: user,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        const userId = userResponse.data.id;

        const otherInfo = {
          prevBankAccNumber: userData.prevBankAccNumber,
          prevBankName: userData.prevBankName,
        };

        const userPersonalInfo = {
          dateOfBirth: userData.dateOfBirth,
          gender: "Male",
          maritalStatus:
            userData.maritalStatus === "Unmarried"
              ? "Single"
              : "Single"
              ? "Single"
              : "Married",
          fatherName: userData.fatherName,
          address: address,
          city: city,
          country: "Nepal",
          mobile: userData.mobile,
          guardianPhone: userData.guardianPhone,
          citizenshipNumber: userData.citizenshipNumber,
          panNumber: userData.panNumber,
          otherInfo: otherInfo,
          userId: userId,
        };

        // Creating personal information
        await axios({
          method: "post",
          url: "/api/personalInformations",
          data: userPersonalInfo,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        const deductionTax = Math.floor(Number(userData.salary) * 0.01);
        const salaryNet = userData.salary - deductionTax;

        const userFinancialInfo = {
          bankName: "Kumari Bank",
          accountName: userData.accountName,
          accountNumber: userData.accountNumber,
          userId: userId,
          employmentType: "Full Time",
          salaryBasic: parseInt(processSalary(userData.salary)),
          deductionTax: deductionTax,
          salaryNet: salaryNet,
        };

        // Creating financial information
        await axios({
          method: "post",
          url: "api/financialInformations",
          data: userFinancialInfo,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
      } catch (err) {
        this.setState({
          hasError: true,
          errMsg: err.response?.data?.message || "An error occurred",
        });
        window.scrollTo(0, 0);
        return;
      }
    }

    this.setState({ completed: true });
    window.scrollTo(0, 0);
  };

  render() {
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      Papa.parse(file, {
        complete: (result) => {
          const nonEmptyData = result.data.filter((row) =>
            Object.values(row).some((value) => value.trim() !== "")
          );
          this.setState({ data: nonEmptyData });
        },
        header: true,
      });
    };

    return (
      <div>
        <input type="file" accept=".csv" onChange={handleFileUpload} />
        <table>
          <thead>
            <tr>
              {this.state.data[0] &&
                Object.keys(this.state.data[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={this.onSubmit}>Submit All</button>
        {this.state.hasError && <p>{this.state.errMsg}</p>}
        {this.state.completed && <p>All users submitted successfully!</p>}
      </div>
    );
  }
}
