import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Modal, Button, Form, Alert } from "react-bootstrap";

const EditVacancy = (props) => {
  const {
    departmentId,
    description,
    dueDate,
    employeeNumber,
    salary,
    id,
    title,
  } = props.location.state.selectedUser;

  const [departments, setDepartments] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [done, setDone] = useState(false);

  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedEmployeeNumber, setUpdatedEmployeeNumber] =
    useState(employeeNumber);
  const [updatedDueDate, setUpdatedDueDate] = useState(dueDate);
  const [updatedSalary, setUpdatedSalary] = useState(salary);
  const [updatedDepartmentId, setUpdatedDepartmentId] = useState(departmentId);

  useEffect(() => {
    axios({
      method: "get",
      url: "/api/departments",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
        setShowAlert(true);
        setErrorMsg("Failed to fetch departments");
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "title":
        setUpdatedTitle(value);
        break;
      case "description":
        setUpdatedDescription(value);
        break;
      case "employeeNumber":
        setUpdatedEmployeeNumber(value);
        break;
      case "dueDate":
        setUpdatedDueDate(value);
        break;
      case "salary":
        setUpdatedSalary(value);
        break;
      case "departmentId":
        setUpdatedDepartmentId(value);
        break;
      default:
        break;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let data = {
      title: updatedTitle,
      description: updatedDescription,
      employeeNumber: updatedEmployeeNumber || 0,
      dueDate: updatedDueDate,
      salary: updatedSalary || 0,
      departmentId: updatedDepartmentId,
    };

    axios({
      method: "put",
      url: `/api/vacancies/${id}`,
      data: data,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        setDone(true);
      })
      .catch((err) => {
        setShowAlert(true);
        setErrorMsg(err.response.data.message);
      });
  };

  return (
    <div>
      {done && <Redirect to="/vacancy-list" />}
      {showAlert && (
        <Alert variant="warning" className="m-1">
          {errorMsg}
        </Alert>
      )}
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Label className="mb-2 required">Title</Form.Label>
          <Form.Control
            type="text"
            className="col-8"
            name="title"
            value={updatedTitle}
            onChange={handleChange}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label className="mb-2 required">Description</Form.Label>
          <Form.Control
            type="text"
            className="col-8"
            name="description"
            value={updatedDescription}
            onChange={handleChange}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="formEmployeeNumber">
          <Form.Label className="mb-2  required">Employee Number</Form.Label>
          <Form.Control
            type="text"
            className="col-8"
            name="employeeNumber"
            value={updatedEmployeeNumber}
            onChange={handleChange}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="formDueDate">
          <Form.Label className="mb-2 required">Due Date</Form.Label>
          <Form.Control
            type="text"
            className="col-8"
            name="dueDate"
            value={updatedDueDate}
            onChange={handleChange}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="formSalary">
          <Form.Label className="mb-2">Salary</Form.Label>
          <Form.Control
            type="text"
            className="col-8"
            name="salary"
            value={updatedSalary}
            onChange={handleChange}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group controlId="formDepartment">
          <Form.Label className="text-muted required">Department</Form.Label>
          <Form.Control
            as="select"
            value={updatedDepartmentId}
            onChange={handleChange}
            name="departmentId"
            required
          >
            <option value="" defaultValue>
              Choose...
            </option>
            {departments.map((dept, index) => (
              <option key={index} value={dept.id}>
                {dept.departmentName}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Button variant="success" type="submit" className="mt-2">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default EditVacancy;
