import React, { Component } from "react";
import { Card, Button, Form, Alert } from "react-bootstrap";
import axios from "axios";

export default class SalaryDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departments: [],
      selectedDepartment: null,
      selectedUser: null,
      financialId: null,
      users: [],
      salaryBasic: 0,
      deductionTax: 0,
      deductionOther: 0,
      hasError: false,
      errMsg: "",
      completed: false,
      employmentType: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleChangeEmploymentType =
      this.handleChangeEmploymentType.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    axios({
      method: "get",
      url: "/api/departments",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ departments: res.data }, () => {
          if (this.props.location.state) {
            this.setState(
              {
                selectedDepartment:
                  this.props.location.state.selectedUser.departmentId,
              },
              () => {
                this.fetchData();
              }
            );
            this.setState(
              { selectedUser: this.props.location.state.selectedUser.id },
              () => {
                this.pushChanges();
              }
            );
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  pushChanges = () => {
    axios({
      method: "get",
      url: "api/financialInformations/user/" + this.state.selectedUser,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({
            financialId: res.data[0].id,
            salaryBasic: res.data[0].salaryBasic || 0,
            deductionTax: res.data[0].deductionTax,
            employmentType: res.data[0].employmentType,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchData = () => {
    axios({
      method: "get",
      url: "api/departments/" + this.state.selectedDepartment,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        let department = res.data;
        let users = [];

        department.users.map((user) => {
          users.push(user);
        });

        this.setState({ users: users });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchDataAll = () => {
    axios({
      method: "get",
      url: "api/departments/",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        let departments = res.data;
        let users = [];

        departments.map((dept) => {
          dept.users.map((user) => {
            users.push(user);
          });
        });

        this.setState({ users: users });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  pushDepartments = () => {
    let items = [];
    items.push(
      <option key={584390} value="all">
        All departments
      </option>
    );
    this.state.departments.map((dept, index) => {
      if (this.state.selectedDepartment == dept.id) {
        items.push(
          <option key={index} value={dept.id} defaultValue>
            {dept.departmentName}
          </option>
        );
      } else {
        items.push(
          <option key={index} value={dept.id}>
            {dept.departmentName}
          </option>
        );
      }
    });
    return items;
  };

  pushUsers = () => {
    let items = [];

    this.state.users.map((user, index) => {
      items.push(
        <option key={index} value={user.id}>
          {user.fullName}
        </option>
      );
    });

    return items;
  };

  handleDepartmentChange = (event) => {
    this.setState({ selectedDepartment: event.target.value }, () => {
      if (this.state.selectedDepartment === "all") {
        this.fetchDataAll();
      } else {
        this.fetchData();
      }
    });
  };

  handleUserChange = (event) => {
    this.state.users.map((user) => {
      if (user.id == event.target.value) {
        this.setState({ selectedUser: event.target.value }, () => {
          this.pushChanges(); // Call to update the fields
        });
      }
    });
  };

  handleChangeEmploymentType = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = +value;
    if (name == "salaryBasic") {
      this.setState((prevState) => ({
        [name]: newValue,
        // Update other state attribute here
        deductionTax: Math.floor(0.01 * newValue), // example update, replace with actual logic
      }));
    }
    this.setState({
      [name]: +value,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();

    let data = {
      employmentType: this.state.employmentType,
      salaryBasic: this.state.salaryBasic,
      deductionTax: this.state.deductionTax,
      salaryNet: this.state.salaryBasic - this.state.deductionTax,
    };

    console.log("Form submitted");

    axios({
      method: "put",
      url: "/api/financialInformations/" + this.state.financialId,
      data: data,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ completed: true });
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        this.setState({ hasError: true, errMsg: err.response.data.message });
        window.scrollTo(0, 0);
      });
  };

  render() {
    let salaryGross = this.state.salaryBasic;

    let deductionTotal = this.state.deductionTax + this.state.deductionOther;

    let salaryNet = salaryGross - deductionTotal;

    return (
      <div className="container-fluid pt-2">
        <div className="row">
          {this.state.hasError ? (
            <Alert variant="danger" className="m-3">
              {this.state.errMsg}
            </Alert>
          ) : this.state.completed ? (
            <Alert variant="success" className="m-3">
              Financial Information have been updated.
            </Alert>
          ) : null}

          <div className="col-sm-12">
            <Card className="main-card">
              <Card.Header>
                <div className="required">Manage Salary Details</div>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                      <Form.Label>Select Department: </Form.Label>
                      <Form.Control
                        as="select"
                        className="select-css"
                        value={this.state.selectedDepartment}
                        onChange={this.handleDepartmentChange}
                      >
                        <option key={34432432} value="">
                          Choose one...
                        </option>
                        {this.pushDepartments()}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Select User: </Form.Label>
                      <Form.Control
                        as="select"
                        className="select-css"
                        value={this.state.selectedUser || ""}
                        onChange={this.handleUserChange}
                      >
                        <option value="">Choose one...</option>
                        {this.pushUsers()}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        {this.state.selectedUser ? (
          <Form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <Card className="main-card">
                  <Card.Header>Salary Details</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Form.Group>
                        <Form.Label className="required">
                          Employment Type{" "}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          className="select-css"
                          value={this.state.employmentType}
                          onChange={this.handleChangeEmploymentType}
                          name="employmentType"
                        >
                          <option value="">Choose one...</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="required">
                          Basic Salary
                        </Form.Label>
                        <Form.Control
                          type="number"
                          value={this.state.salaryBasic}
                          onChange={this.handleChange}
                          name="salaryBasic"
                        />
                      </Form.Group>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                className="main-card"
                style={{ margin: "20px 0", width: "400px" }}
              >
                <Card.Header>Total Salary Details</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Form.Group>
                      <Form.Label>Gross Salary</Form.Label>
                      <Form.Control
                        type="number"
                        value={salaryGross}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Total Deductions</Form.Label>
                      <Form.Control
                        type="number"
                        value={deductionTotal}
                        readOnly
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Net Salary</Form.Label>
                      <Form.Control type="number" value={salaryNet} readOnly />
                    </Form.Group>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div
              className="row mb-2"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        ) : null}
      </div>
    );
  }
}
