import React, { Component } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { Redirect, NavLink } from "react-router-dom";
import axios from "axios";


export default class EmployeeEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        id: null,
        fullName: "",
        role: null,
        active: null,
        departmentId: null,
        userName: "",
        password: "",
        startDate: null,
        endDate: null,
        jobTitle: "",
      },
      userPersonalInfo: {
        id: null,
        dateOfBirth: null,
        gender: "",
        maritalStatus: "",
        fatherName: "",
        address: "",
        city: "",
        country: "",
        mobile: "",
        guardianPhone: null,
        citizenshipNumber: null,
        panNumber: null,
        prevBankName: "",
        prevBankAccNumber: "",
        laptop: "",
      },
      userFinancialInfo: {
        id: null,
        bankName: "",
        accountName: "",
        accountNumber: "",
      },
      department: {
        departmentId: null,
        departmentName: null,
      },
      departments: [],
      hasError: false,
      errMsg: "",
      completed: false,
      falseRedirect: false,
    };
  }

  async componentDidMount() {
    if (this.props.location.state) {
      try {
        const userResponse = await axios({
          method: "get",
          url: "api/users/" + this.props.location.state.selectedUser.id,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },

        });

        let user = userResponse.data;

        this.setState({ user: user });

        this.setState({ department: user.department });
        const { laptop, prevBankAccNumber, prevBankName } =
          user.user_personal_info.otherInfo;
        const {
          address,
          citizenshipNumber,
          city,
          country,
          dateOfBirth,
          fatherName,
          gender,
          guardianPhone,
          id,
          maritalStatus,
          mobile,
          panNumber,
          userId,
        } = user.user_personal_info;
        const finalPersonal = {
          laptop,
          prevBankAccNumber,
          prevBankName,
          address,
          citizenshipNumber,
          city,
          country,
          dateOfBirth,
          fatherName,
          gender,
          guardianPhone,
          id,
          maritalStatus,
          mobile,
          panNumber,
          userId,
        };
        this.setState({ userPersonalInfo: finalPersonal });

        this.setState({ userFinancialInfo: user.user_financial_info });

        const departmentsResponse = await axios({
          method: "get",
          url: "/api/departments",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },

        });

        this.setState({ departments: departmentsResponse.data });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({ falseRedirect: true });
    }
  }

  handleChangeUser = (event) => {
    const { value, name } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));
  };

  handleChangeDepartment = (event) => {
    const { value, name } = event.target;
    this.setState((prevState) => ({
      department: {
        ...prevState.department,
        [name]: value,
      },
    }));
  };

  handleChangeUserPersonal = (event) => {
    const { value, name } = event.target;
    this.setState((prevState) => ({
      userPersonalInfo: {
        ...prevState.userPersonalInfo,
        [name]: value,
      },
    }));
  };

  handleChangeUserFinancial = (event) => {
    const { value, name } = event.target;
    this.setState((prevState) => ({
      userFinancialInfo: {
        ...prevState.userFinancialInfo,
        [name]: value,
      },
    }));
  };

  pushDepartments = () => {
    let items = [];
    this.state.departments.map((dept, index) => {
      items.push(
        <option key={index} value={dept.id}>
          {dept.departmentName}
        </option>
      );
    });
    return items;
  };


  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({ hasError: false, errorMsg: "", completed: false });

    let user = {
      fullName: this.state.user.fullName,
      role: this.state.user.role,
      departmentId: this.state.user.departmentId,
      active: this.state.user.active,
      userName: this.state.user.userName,
      password: this.state.user.password,
      startDate: this.state.user.startDate,
      endDate: this.state.user.endDate,
      jobTitle: this.state.user.jobTitle,

    };

    try {
      const userResponse = await axios({
        method: "put",
        url: "/api/users/" + this.props.location.state.selectedUser.id,
        data: user,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      let user_id = userResponse.data.id;
      const otherInfo = {
        prevBankAccNumber: this.state.userPersonalInfo.prevBankAccNumber,
        prevBankName: this.state.userPersonalInfo.prevBankName,
        laptop: this.state.userPersonalInfo.laptop,
      };

      let userPersonalData = {
        dateOfBirth: this.state.userPersonalInfo.dateOfBirth,
        gender: this.state.userPersonalInfo.gender,
        maritalStatus: this.state.userPersonalInfo.maritalStatus,
        fatherName: this.state.userPersonalInfo.fatherName,
        address: this.state.userPersonalInfo.address,
        city: this.state.userPersonalInfo.city,
        country: this.state.userPersonalInfo.country,
        mobile: this.state.userPersonalInfo.mobile,
        guardianPhone: this.state.userPersonalInfo.guardianPhone,
        citizenshipNumber: this.state.user.citizenshipNumber,
        panNumber: this.state.user.panNumber,
        otherInfo: otherInfo,
        userId: user_id,

      };

      try {
        await axios({
          method: "put",
          url: "/api/personalInformations/" + this.state.userPersonalInfo.id,
          data: userPersonalData,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });

        let userFinancialInfo = {
          bankName: this.state.userFinancialInfo.bankName,
          accountName: this.state.userFinancialInfo.accountName,
          accountNumber: this.state.userFinancialInfo.accountNumber,
          userId: user_id,
          chosenDB: localStorage.getItem("chosenDB"),
        };

        try {
          await axios({
            method: "put",
            url: "api/financialInformations/" + this.state.userFinancialInfo.id,
            data: userFinancialInfo,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          this.setState({ completed: true });
        } catch (err) {
          console.log(err);
          this.setState({ hasError: true, errMsg: err.response.data.message });
          window.scrollTo(0, 0);
        }
      } catch (err) {
        this.setState({ hasError: true, errMsg: err.response.data.message });
        window.scrollTo(0, 0);
      }
    } catch (err) {
      console.log(err);
      this.setState({ hasError: true, errMsg: err.response.data.message });
      window.scrollTo(0, 0);
    }
  };

  render() {
    if (
      this.state.user.id === null ||
      this.state.userPersonalInfo.id === null ||
      this.state.userFinancialInfo.id === null
    ) {
      return <p>Loading...</p>;
    }
    return (
      <Form onSubmit={this.onSubmit}>
        <div className="row">
          {this.state.falseRedirect ? <Redirect to="/" /> : null}
          {this.state.hasError ? (
            <Alert variant="danger" className="m-3" block>
              {this.state.errMsg}
            </Alert>
          ) : this.state.completed ? (
            <Redirect to="employee-list" />
          ) : (
            <></>
          )}

          {/* Main Card */}
          <Card className="col-sm-12 main-card">
            <Card.Header>
              <b>Edit Employee</b>
            </Card.Header>
            <Card.Body>
              <div className="row">
                {/* Personal Details Card */}
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Personal Details</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formFullName">
                          <Form.Label className="text-muted required">
                            Full Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter first Name"
                            name="fullName"
                            value={this.state.user.fullName}
                            onChange={this.handleChangeUser}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formJobTitle">
                          <Form.Label className="text-muted required">
                            Job Title
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Job Title"
                            name="jobTitle"
                            value={this.state.user.jobTitle}
                            onChange={this.handleChangeUser}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formDateOfBirth">
                          <Form.Label className="text-muted required">
                            Date of Birth
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter date of birth"
                            name="dateOfBirth"
                            value={this.state.userPersonalInfo.dateOfBirth}
                            onChange={this.handleChangeUserPersonal}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formGender">
                          <Form.Label className="text-muted required">
                            Gender
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.userPersonalInfo.gender}
                            onChange={this.handleChangeUserPersonal}
                            name="gender"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMaritalStatus">
                          <Form.Label className="text-muted required">
                            Marital Status
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.userPersonalInfo.maritalStatus}
                            onChange={this.handleChangeUserPersonal}
                            name="maritalStatus"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="Married">Married</option>
                            <option value="Single">Single</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                          <Form.Label className="text-muted required">
                            Email
                          </Form.Label>
                          <Form.Control
                            type="email"
                            value={this.state.user.email || ""}
                            onChange={this.handleChangeUser}
                            name="phone"
                            placeholder="Enter Email address"
                          />
                        </Form.Group>
                        <Form.Group controlId="formPanNumber">
                          <Form.Label className="text-muted required">
                            PAN number{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.panNumber}
                            onChange={this.handleChangeUserPersonal}
                            name="panNumber"
                            placeholder="Enter PAN number"
                          />
                        </Form.Group>
                        <Form.Group controlId="formCitizenshipNumber">
                          <Form.Label className="text-muted required">
                            Citizenship Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={
                              this.state.userPersonalInfo.citizenshipNumber
                            }
                            onChange={this.handleChangeUserPersonal}
                            name="citizenshipNumber"
                            placeholder="Enter citizenship number"
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Contact Details</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formPhysicalAddress">
                          <Form.Label className="text-muted required">
                            Physical Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.address}
                            onChange={this.handleChangeUserPersonal}
                            name="address"
                            placeholder="Enter Address"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formCountry">
                          <Form.Label className="text-muted required">
                            Country
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.country}
                            onChange={this.handleChangeUserPersonal}
                            name="country"
                            placeholder="Enter Country"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formCity">
                          <Form.Label className="text-muted required">
                            City
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.city}
                            onChange={this.handleChangeUserPersonal}
                            name="city"
                            placeholder="Enter City"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formMobile">
                          <Form.Label className="text-muted required">
                            Mobile
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.mobile}
                            onChange={this.handleChangeUserPersonal}
                            name="mobile"
                            placeholder="Enter Mobile"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formGuardianPhone">
                          <Form.Label className="text-muted">
                            Guardian Phone
                          </Form.Label>
                          <Form.Control
                            type="number"
                            value={this.state.userPersonalInfo.guardianPhone}
                            onChange={this.handleChangeUserPersonal}
                            name="guardianPhone"
                            placeholder="Enter Guardian Phone number"
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formFatherName">
                          <Form.Label className="text-muted required">
                            Guardian's name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Father's Name"
                            name="fatherName"
                            value={this.state.userPersonalInfo.fatherName}
                            onChange={this.handleChangeUserPersonal}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formStartDate">
                          <Form.Label className="text-muted">
                            Start Date
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Start Date"
                            name="startDate"
                            value={this.state.user.startDate}
                            onChange={this.handleChangeUser}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formEndDate">
                          <Form.Label className="text-muted required">
                            End Date
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter End date"
                            name="endDate"
                            value={this.state.user.endDate}
                            onChange={this.handleChangeUser}
                            required
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Bank Information</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formBankName">
                          <Form.Label className="text-muted">
                            Bank Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userFinancialInfo.bankName}
                            onChange={this.handleChangeUserFinancial}
                            name="bankName"
                            placeholder="Enter Bank name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formAccountName">
                          <Form.Label className="text-muted">
                            Account Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userFinancialInfo.accountName}
                            onChange={this.handleChangeUserFinancial}
                            name="accountName"
                            placeholder="Enter Account name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formAccountNumber">
                          <Form.Label className="text-muted">
                            Account Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userFinancialInfo.accountNumber}
                            onChange={this.handleChangeUserFinancial}
                            name="accountNumber"
                            placeholder="Enter Account number"
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Official Status</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formUserName">
                          <Form.Label className="text-muted">
                            User Name{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.user.userName}
                            onChange={this.handleChangeUser}
                            name="userName"
                            placeholder="Enter user name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formPassword">
                          <Form.Label className="text-muted">
                            Password{" "}
                          </Form.Label>
                          <Form.Control
                            type="password"
                            value={this.state.user.password}
                            onChange={this.handleChangeUser}
                            name="password"
                            placeholder="Enter password"
                          />
                        </Form.Group>
                        <Form.Group controlId="formDepartment">
                          <Form.Label className="text-muted required">
                            Department
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.user.departmentId}
                            onChange={this.handleChangeUser}
                            name="departmentId"
                            required
                          >
                            {this.pushDepartments()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formRole">
                          <Form.Label className="text-muted required">
                            Role
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.user.role || ""}
                            onChange={this.handleChangeUser}
                            name="role"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="ROLE_EMPLOYEE">Employee</option>
                            <option value="ROLE_MANAGER">Manager</option>
                            <option value="ROLE_ADMIN">Admin</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formActive">
                          <Form.Label className="text-muted required">
                            Status
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.user.active}
                            onChange={this.handleChangeUser}
                            name="active"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value={false}>Inactive</option>
                            <option value={true}>Active</option>
                          </Form.Control>
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Extras</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formPrevBankName">
                          <Form.Label className="text-muted">
                            Previous Bank Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.prevBankName}
                            onChange={this.handleChangeUserPersonal}
                            name="prevBankName"
                            placeholder="Enter previous bank name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formPrevBankAccNumber">
                          <Form.Label className="text-muted">
                            Previous Bank Account Number
                          </Form.Label>
                          <Form.Control
                            type="password"
                            value={
                              this.state.userPersonalInfo.prevBankAccNumber
                            }
                            onChange={this.handleChangeUserPersonal}
                            name="prevBankAccNumber"
                            placeholder="Enter previous bank account number"
                          />
                        </Form.Group>
                        <Form.Group controlId="formLaptop">
                          <Form.Label className="text-muted">Laptop</Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userPersonalInfo.laptop}
                            onChange={this.handleChangeUserPersonal}
                            name="laptop"
                            placeholder="Enter laptop info"
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "240px" }}
                >
                  Submit
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Form>
    );
  }
}
