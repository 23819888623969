// import React, { Component } from "react";
// import { Card, Button, Form, Alert } from "react-bootstrap";
// import CandidateAdd from "./CandidateAdd";
// import EditDepartmentModal from "./EditDepartmentModal";
// import axios from "axios";
// import MaterialTable from "material-table";
// import { ThemeProvider } from "@material-ui/core";
// import { createMuiTheme } from "@material-ui/core/styles";
// import AlertModal from "./AlertModal";

// export default class DepartmentList extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       departments: [],
//       selectedCandidate: null,
//       vacancyId: null,
//       hasError: false,
//       errorMsg: "",
//       completed: false,
//       showEditModel: false,
//       showAlertModel: false,
//     };
//   }

//   componentDidMount() {
//     this.setState({ vacancyId: this.props.location.state.selectedVacancy.id });
//     this.fetchCandidates(this.props.location.state.selectedVacancy.id);
//   }

//   fetchCandidates = (vacancyId) => {
//     axios({
//       method: "get",
//       url: `/api/candidates/vacancy/${vacancyId}`,
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     })
//       .then((res) => {
//         console.log(res.data);
//         this.setState({ departments: res.data });
//       })
//       .catch((err) => {
//         this.setState({
//           hasError: true,
//           errorMsg: err.response
//             ? err.response.data.message
//             : "Error fetching candidates",
//         });
//       });
//   };

//   onApprove = (department) => (event) => {
//     event.preventDefault();
//     axios({
//       method: "put",
//       url: "/api/candidates/" + department.id,
//       data: { applicationStatus: "accepted" },
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     })
//       .then((res) => {
//         this.fetchCandidates(this.state.vacancyId);
//       })
//       .catch((err) => {
//         this.setState({
//           hasError: true,
//           errorMsg: err.response.data.message,
//         });
//       });
//   };

//   onReject = (department) => (event) => {
//     event.preventDefault();
//     axios({
//       method: "put",
//       url: "/api/candidates/" + department.id,
//       data: { applicationStatus: "rejected" },
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     })
//       .then((res) => {
//         this.fetchCandidates(this.state.vacancyId);
//       })
//       .catch((err) => {
//         this.setState({
//           hasError: true,
//           errorMsg: err.response.data.message,
//         });
//       });
//   };

//   getStatusStyles(status) {
//     switch (status) {
//       case "pending":
//         return {
//           backgroundColor: "#FF5733",
//           color: "white",
//           padding: "5px 10px",
//           borderRadius: "4px",
//           textAlign: "center",
//         };
//       case "rejected":
//         return {
//           backgroundColor: "darkred",
//           color: "white",
//           padding: "5px 10px",
//           borderRadius: "4px",
//           textAlign: "center",
//         };
//       case "accepted":
//         return {
//           backgroundColor: "darkgreen",
//           color: "white",
//           padding: "5px 10px",
//           borderRadius: "4px",
//           textAlign: "center",
//         };
//       default:
//         return {};
//     }
//   }

//   render() {
//     const buttonStyles = {
//       display: "flex",
//       flexDirection: "row",
//       alignItems: "center",
//       gap: "2px",
//     };
//     let closeEditModel = () => this.setState({ showEditModel: false });
//     let closeAlertModel = () => this.setState({ showAlertModel: false });

//     const theme = createMuiTheme({
//       overrides: {
//         MuiTableCell: {
//           root: {
//             padding: "6px 6px 6px 6px",
//           },
//         },
//       },
//     });

//     return (
//       <div className="container-fluid pt-2">
//         <div className="row">
//           <div className="col-sm-12">
//             <CandidateAdd
//               vacancyId={this.state.vacancyId}
//               onCandidateAdded={() =>
//                 this.fetchCandidates(this.state.vacancyId)
//               }
//             />
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-sm-12">
//             <Card className="main-card">
//               <Card.Header>
//                 <div className="panel-title">
//                   <strong>Candidate List</strong>
//                 </div>
//               </Card.Header>
//               <Card.Body>
//                 <ThemeProvider theme={theme}>
//                   <MaterialTable
//                     columns={[
//                       { title: "ID", field: "id" },
//                       { title: "Name", field: "fullName" },
//                       { title: "Email Address", field: "email" },
//                       { title: "City", field: "city" },
//                       { title: "Resume", field: "resume" },
//                       {
//                         title: "Status",
//                         field: "applicationStatus",
//                         render: (rowData) => (
//                           <span
//                             style={this.getStatusStyles(
//                               rowData.applicationStatus
//                             )}
//                           >
//                             {rowData.applicationStatus}
//                           </span>
//                         ),
//                       },
//                       {
//                         title: "Action",
//                         render: (rowData) =>
//                           rowData.applicationStatus === "pending" ? (
//                             <Form className="row">
//                               <div className="col pl-5">
//                                 <div
//                                   style={{
//                                     display: "flex",
//                                     flexDirection: "row",
//                                     gap: "4px",
//                                     alignItems: "center",
//                                   }}
//                                 >
//                                   <Button
//                                     style={buttonStyles}
//                                     size="sm"
//                                     variant="info"
//                                     onClick={this.onApprove(rowData)}
//                                   >
//                                     <i className="fas fa-edit"></i>Approve
//                                   </Button>
//                                   <Button
//                                     style={buttonStyles}
//                                     size="sm"
//                                     variant="info"
//                                     onClick={this.onReject(rowData)}
//                                   >
//                                     <i className="fas fa-edit"></i>Reject
//                                   </Button>
//                                 </div>
//                               </div>
//                             </Form>
//                           ) : null,
//                       },
//                     ]}
//                     data={this.state.departments}
//                     options={{
//                       rowStyle: (rowData, index) => {
//                         if (index % 2) {
//                           return { backgroundColor: "#f2f2f2" };
//                         }
//                       },
//                       pageSize: 8,
//                       pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
//                     }}
//                     title="Candidates"
//                   />
//                 </ThemeProvider>
//               </Card.Body>
//             </Card>
//             {this.state.showEditModel ? (
//               <EditDepartmentModal
//                 show={true}
//                 onHide={closeEditModel}
//                 data={this.state.selectedDepartment}
//               />
//             ) : this.state.showAlertModel ? (
//               <AlertModal show={true} onHide={closeAlertModel} />
//             ) : (
//               <></>
//             )}
//           </div>
//         </div>
//         {this.state.hasError ? (
//           <Alert variant="danger" className="m-3" block>
//             {this.state.errorMsg}
//           </Alert>
//         ) : (
//           <></>
//         )}
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import { Card, Button, Form, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom"; // Import withRouter
import CandidateAdd from "./CandidateAdd";
import EditDepartmentModal from "./EditDepartmentModal";
import axios from "axios";
import MaterialTable from "material-table";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import AlertModal from "./AlertModal";

class DepartmentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      departments: [],
      selectedCandidate: null,
      vacancyId: null,
      hasError: false,
      errorMsg: "",
      completed: false,
      showEditModel: false,
      showAlertModel: false,
    };
  }

  componentDidMount() {
    this.setState({ vacancyId: this.props.location.state.selectedVacancy.id });
    this.fetchCandidates(this.props.location.state.selectedVacancy.id);
  }

  fetchCandidates = (vacancyId) => {
    axios({
      method: "get",
      url: `/api/candidates/vacancy/${vacancyId}`,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        console.log(res.data);
        this.setState({ departments: res.data });
      })
      .catch((err) => {
        this.setState({
          hasError: true,
          errorMsg: err.response
            ? err.response.data.message
            : "Error fetching candidates",
        });
      });
  };

  onApprove = (department) => (event) => {
    event.preventDefault();
    axios({
      method: "put",
      url: "/api/candidates/" + department.id,
      data: { applicationStatus: "accepted" },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.fetchCandidates(this.state.vacancyId);

        // Navigate to AddEmployee page and pass department data as props
        this.props.history.push({
          pathname: "/employee-add",
          state: { departmentData: department },
        });
      })
      .catch((err) => {
        this.setState({
          hasError: true,
          errorMsg: err.response.data.message,
        });
      });
  };

  onReject = (department) => (event) => {
    event.preventDefault();
    axios({
      method: "put",
      url: "/api/candidates/" + department.id,
      data: { applicationStatus: "rejected" },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.fetchCandidates(this.state.vacancyId);
      })
      .catch((err) => {
        this.setState({
          hasError: true,
          errorMsg: err.response.data.message,
        });
      });
  };

  getStatusStyles(status) {
    switch (status) {
      case "pending":
        return {
          backgroundColor: "#FF5733",
          color: "white",
          padding: "5px 10px",
          borderRadius: "4px",
          textAlign: "center",
        };
      case "rejected":
        return {
          backgroundColor: "darkred",
          color: "white",
          padding: "5px 10px",
          borderRadius: "4px",
          textAlign: "center",
        };
      case "accepted":
        return {
          backgroundColor: "darkgreen",
          color: "white",
          padding: "5px 10px",
          borderRadius: "4px",
          textAlign: "center",
        };
      default:
        return {};
    }
  }

  render() {
    const buttonStyles = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "2px",
    };

    let closeEditModel = () => this.setState({ showEditModel: false });
    let closeAlertModel = () => this.setState({ showAlertModel: false });

    const theme = createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            padding: "6px 6px 6px 6px",
          },
        },
      },
    });

    return (
      <div className="container-fluid pt-2">
        <div className="row">
          <div className="col-sm-12">
            <CandidateAdd
              vacancyId={this.state.vacancyId}
              onCandidateAdded={() =>
                this.fetchCandidates(this.state.vacancyId)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Card className="main-card">
              <Card.Header>
                <div className="panel-title">
                  <strong>Candidate List</strong>
                </div>
              </Card.Header>
              <Card.Body>
                <ThemeProvider theme={theme}>
                  <MaterialTable
                    columns={[
                      { title: "ID", field: "id" },
                      { title: "Name", field: "fullName" },
                      { title: "Email Address", field: "email" },
                      { title: "City", field: "city" },
                      { title: "Resume", field: "resume" },
                      {
                        title: "Status",
                        field: "applicationStatus",
                        render: (rowData) => (
                          <span
                            style={this.getStatusStyles(
                              rowData.applicationStatus
                            )}
                          >
                            {rowData.applicationStatus}
                          </span>
                        ),
                      },
                      {
                        title: "Action",
                        render: (rowData) =>
                          rowData.applicationStatus === "pending" ? (
                            <Form className="row">
                              <div className="col pl-5">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button
                                    style={buttonStyles}
                                    size="sm"
                                    variant="info"
                                    onClick={this.onApprove(rowData)}
                                  >
                                    <i className="fas fa-edit"></i>Approve
                                  </Button>
                                  <Button
                                    style={buttonStyles}
                                    size="sm"
                                    variant="info"
                                    onClick={this.onReject(rowData)}
                                  >
                                    <i className="fas fa-edit"></i>Reject
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          ) : null,
                      },
                    ]}
                    data={this.state.departments}
                    options={{
                      rowStyle: (rowData, index) => {
                        if (index % 2) {
                          return { backgroundColor: "#f2f2f2" };
                        }
                      },
                      pageSize: 8,
                      pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                    }}
                    title="Candidates"
                  />
                </ThemeProvider>
              </Card.Body>
            </Card>
            {this.state.showEditModel ? (
              <EditDepartmentModal
                show={true}
                onHide={closeEditModel}
                data={this.state.selectedDepartment}
              />
            ) : this.state.showAlertModel ? (
              <AlertModal show={true} onHide={closeAlertModel} />
            ) : (
              <></>
            )}
          </div>
        </div>
        {this.state.hasError ? (
          <Alert variant="danger" className="m-3" block>
            {this.state.errorMsg}
          </Alert>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withRouter(DepartmentList); // Wrap the component with withRouter
