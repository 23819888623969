// import React, { Component } from "react";
// import { Card, Badge, Button, Form, Modal } from "react-bootstrap";
// import { Redirect } from "react-router-dom";
// import MaterialTable from "material-table";
// import DeleteModal from "./DeleteModal";
// import axios from "axios";
// import { ThemeProvider } from "@material-ui/core";
// import { createMuiTheme } from "@material-ui/core/styles";

// export default class EmployeeList extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       vacancies: [],
//       selectedVacancy: null,
//       viewRedirect: false,
//       editRedirect: false,
//       deleteModal: false,
//     };
//   }

//   componentDidMount() {
//     axios({
//       method: "get",
//       url: "/api/vacancies",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     })
//       .then((res) => {
//         const openVacancies = res.data.filter((vacancy) => vacancy.status === "open")
//         this.setState({ vacancies: res.data });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }
//   onView = (user) => {
//     return (event) => {
//       event.preventDefault();

//       this.setState({ selectedVacancy: user, viewRedirect: true });
//     };
//   };

//   onEdit = (user) => {
//     return (event) => {
//       event.preventDefault();

//       this.setState({ selectedVacancy: user, editRedirect: true });
//     };
//   };

//   onClose = (user) => {
//     return (event) => {
//       event.preventDefault();

//       this.setState({ selectedVacancy: user, deleteModal: true });
//     };
//   };

//   render() {
//     let closeDeleteModel = () => this.setState({ deleteModal: false });

//     const theme = createMuiTheme({
//       overrides: {
//         MuiTableCell: {
//           root: {
//             padding: "6px 6px 6px 6px",
//           },
//         },
//       },
//     });

//     return (
//       <div className="container-fluid pt-4">
//         {this.state.viewRedirect ? (
//           <Redirect
//             to={{
//               pathname: "/candidates",
//               state: { selectedVacancy: this.state.selectedVacancy },
//             }}
//           ></Redirect>
//         ) : (
//           <></>
//         )}
//         {this.state.editRedirect ? (
//           <Redirect
//             to={{
//               pathname: "/vacancy-edit",
//               state: { selectedUser: this.state.selectedVacancy },
//             }}
//           ></Redirect>
//         ) : (
//           <></>
//         )}
//         {this.state.deleteModal ? (
//           <DeleteModal
//             show={true}
//             onHide={closeDeleteModel}
//             data={this.state.selectedVacancy}
//           />
//         ) : (
//           <></>
//         )}
//         <h4>
//           <a className="fa fa-plus mb-2 ml-2" href="/vacancy-add">
//             Add Vacancy
//           </a>
//         </h4>
//         <div className="col-sm-12">
//           <Card>
//             <Card.Header style={{ backgroundColor: "#515e73", color: "white" }}>
//               <div className="panel-title">
//                 <strong>Vacancy List</strong>
//               </div>
//             </Card.Header>
//             <Card.Body>
//               <ThemeProvider theme={theme}>
//                 <MaterialTable
//                   columns={[
//                     { title: "Vacancy ID", field: "id" },
//                     { title: "Title", field: "title" },
//                     { title: "Description", field: "description" },
//                     { title: "Employee Number", field: "employeeNumber" },
//                     { title: "Due Date", field: "dueDate" },
//                     { title: "Salary", field: "salary" },
//                     { title: "Department", field: "department.departmentName" },
//                     {
//                       title: "View",
//                       render: (rowData) => (
//                         <Form>
//                           <Button
//                             size="sm"
//                             variant="info"
//                             onClick={this.onView(rowData)}
//                           >
//                             <i className="far fa-address-card"></i>
//                           </Button>
//                         </Form>
//                       ),
//                     },
//                     {
//                       title: "Action",
//                       render: (rowData) => (
//                         <div
//                           style={{
//                             display: "flex",
//                             flexDirection: "row",
//                             alignItems: "center",
//                           }}
//                         >
//                           <Button
//                             size="sm"
//                             variant="info"
//                             className="mr-2"
//                             style={{ display: "flex", alignItems: "center" }}
//                             onClick={this.onEdit(rowData)}
//                           >
//                             <i className="far fa-edit"></i>Edit
//                           </Button>
//                           {rowData.id !==
//                           JSON.parse(localStorage.getItem("user")).id ? (
//                             <Button
//                               size="sm"
//                               variant="danger"
//                               className="ml-1"
//                               onClick={this.onClose(rowData)}
//                             >
//                               <i className="far fa-bin"></i>Close
//                             </Button>
//                           ) : (
//                             <></>
//                           )}
//                         </div>
//                       ),
//                     },
//                   ]}
//                   data={this.state.vacancies}
//                   options={{
//                     rowStyle: (rowData, index) => {
//                       if (index % 2) {
//                         return { backgroundColor: "#f2f2f2" };
//                       }
//                     },
//                     pageSize: 10,
//                     pageSizeOptions: [10, 20, 30, 50, 75, 100],
//                   }}
//                   title="Vacancies"
//                 />
//               </ThemeProvider>
//             </Card.Body>
//           </Card>
//         </div>
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import MaterialTable from "material-table";
import DeleteModal from "./DeleteModal";
import axios from "axios";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

export default class VacancyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vacancies: [],
      selectedVacancy: null,
      viewRedirect: false,
      editRedirect: false,
      deleteModal: false,
    };
  }

  componentDidMount() {
    this.fetchVacancies();
  }

  fetchVacancies = () => {
    axios({
      method: "get",
      url: "/api/vacancies",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        const openVacancies = res.data.filter(
          (vacancy) => vacancy.status === "open"
        );
        this.setState({ vacancies: openVacancies });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onView = (user) => {
    return (event) => {
      event.preventDefault();

      this.setState({ selectedVacancy: user, viewRedirect: true });
    };
  };

  onEdit = (user) => {
    return (event) => {
      event.preventDefault();

      this.setState({ selectedVacancy: user, editRedirect: true });
    };
  };

  onClose = (user) => {
    return (event) => {
      event.preventDefault();

      this.setState({ selectedVacancy: user, deleteModal: true });
    };
  };

  render() {
    let closeDeleteModel = () => this.setState({ deleteModal: false });

    const theme = createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            padding: "6px 6px 6px 6px",
          },
        },
      },
    });

    return (
      <div className="container-fluid pt-4">
        {this.state.viewRedirect ? (
          <Redirect
            to={{
              pathname: "/candidates",
              state: { selectedVacancy: this.state.selectedVacancy },
            }}
          ></Redirect>
        ) : (
          <></>
        )}
        {this.state.editRedirect ? (
          <Redirect
            to={{
              pathname: "/vacancy-edit",
              state: { selectedUser: this.state.selectedVacancy },
            }}
          ></Redirect>
        ) : (
          <></>
        )}
        {this.state.deleteModal ? (
          <DeleteModal
            show={true}
            onHide={closeDeleteModel}
            data={this.state.selectedVacancy}
            refreshVacancies={this.fetchVacancies} // Pass the callback function
          />
        ) : (
          <></>
        )}
        <h4>
          <a className="fa fa-plus mb-2 ml-2" href="/vacancy-add">
            Add Vacancy
          </a>
        </h4>
        <div className="col-sm-12">
          <Card>
            <Card.Header style={{ backgroundColor: "#515e73", color: "white" }}>
              <div className="panel-title">
                <strong>Vacancy List</strong>
              </div>
            </Card.Header>
            <Card.Body>
              <ThemeProvider theme={theme}>
                <MaterialTable
                  columns={[
                    { title: "Vacancy ID", field: "id" },
                    { title: "Title", field: "title" },
                    { title: "Description", field: "description" },
                    { title: "Employee Number", field: "employeeNumber" },
                    { title: "Due Date", field: "dueDate" },
                    { title: "Salary", field: "salary" },
                    { title: "Department", field: "department.departmentName" },
                    {
                      title: "View",
                      render: (rowData) => (
                        <Form>
                          <Button
                            size="sm"
                            variant="info"
                            onClick={this.onView(rowData)}
                          >
                            <i className="far fa-address-card"></i>
                          </Button>
                        </Form>
                      ),
                    },
                    {
                      title: "Action",
                      render: (rowData) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            size="sm"
                            variant="info"
                            className="mr-2"
                            style={{ display: "flex", alignItems: "center" }}
                            onClick={this.onEdit(rowData)}
                          >
                            <i className="far fa-edit"></i>Edit
                          </Button>
                          {rowData.id !==
                          JSON.parse(localStorage.getItem("user")).id ? (
                            <Button
                              size="sm"
                              variant="danger"
                              className="ml-1"
                              onClick={this.onClose(rowData)}
                            >
                              <i className="far fa-bin"></i>Close
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  data={this.state.vacancies}
                  options={{
                    rowStyle: (rowData, index) => {
                      if (index % 2) {
                        return { backgroundColor: "#f2f2f2" };
                      }
                    },
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 50, 75, 100],
                  }}
                  title="Vacancies"
                />
              </ThemeProvider>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
