import React, { Component } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default class CandidateAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      location: "",
      resume: "",
      city: "",
      hasError: false,
      errMsg: "",
      completed: false,
    };
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ hasError: false, errorMsg: "", completed: false });
    const { vacancyId, onCandidateAdded } = this.props;

    const fullName = `${this.state.firstName} ${this.state.lastName}`;

    let candidate = {
      fullName: fullName,
      email: this.state.email,
      location: this.state.location,
      resume: this.state.resume,
      city: this.state.city,
      vacancyId: vacancyId,
    };

    axios({
      method: "post",
      url: "/api/candidates",
      data: { candidate },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ completed: true });
        if (onCandidateAdded) onCandidateAdded();
        this.setState({
          fullName: "",
          email: "",
          location: "",
          resume: "",
          city: "",
        });
      })
      .catch((err) => {
        this.setState({ hasError: true, errMsg: err.response.data.message });
        window.scrollTo(0, 0);
      });
  };

  render() {
    return (
      <Card className="mb-3 secondary-card">
        <Card.Header>
          <b>Add Candidate</b>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <Form onSubmit={this.onSubmit}>
              <Form.Group controlId="formFirstName">
                <Form.Label className="text-muted mb-2  required">
                  First Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="firstName"
                  style={{ width: "50%" }}
                  value={this.state.fullName}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formLastName">
                <Form.Label className="text-muted mb-2  required">
                  Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="lastName"
                  style={{ width: "50%" }}
                  value={this.state.fullName}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label className="text-muted mb-2">
                  Email address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email address"
                  name="email"
                  style={{ width: "50%" }}
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formResume">
                <Form.Label className="text-muted mb-2">Resume</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your resume link"
                  name="resume"
                  style={{ width: "50%" }}
                  value={this.state.resume}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formDueDate">
                <Form.Label className="text-muted mb-2  required">
                  City
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your city"
                  name="city"
                  style={{ width: "50%" }}
                  value={this.state.city}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2">
                Add
              </Button>
            </Form>
          </Card.Text>
        </Card.Body>
        {this.state.hasError ? (
          <Alert variant="danger" className="m-3" block>
            {this.state.errMsg}
          </Alert>
        ) : (
          <></>
        )}
      </Card>
    );
  }
}
