// import React, { Component } from "react";
// import { Card, Form, Button, Alert } from "react-bootstrap";
// import axios from "axios";

// export default class EmployeeAdd extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       fistname: "",
//       lastname: "",
//       dateOfBirth: "",
//       gender: "",
//       maritalStatus: "",
//       fathername: "",
//       citizenshipNumber: "",
//       bankName: "",
//       accountName: "",
//       accountNumber: "",
//       panNumber: "",
//       address: "",
//       country: "",
//       city: "",
//       mobile: null,
//       phone: null,
//       email: "",
//       userName: "",
//       password: "",
//       role: "",
//       department: "",
//       departmentId: null,
//       startDate: "",
//       endDate: "",
//       departments: [],
//       jobTitle: null,
//       file: null,
//       hasError: false,
//       errMsg: "",
//       completed: false,
//       prevBankName: "",
//       prevBankAccNumber: "",
//       laptop: "",
//     };
//   }

//   componentDidMount() {
//     axios({
//       method: "get",
//       url: "/api/departments",
//       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//     })
//       .then((res) => {
//         this.setState({ departments: res.data });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }

//   handleChange = (event) => {
//     const { value, name } = event.target;
//     this.setState({
//       [name]: value,
//     });
//   };

//   fileSelectedHandler = (event) => {
//     this.setState({
//       file: event.target.files[0],
//     });
//   };

//   onSubmit = async (e) => {
//     console.log(this.state);
//     e.preventDefault();
//     this.setState({ hasError: false, errorMsg: "", completed: false });

//     const isUserNameEntered = this.state.userName !== "";
//     const isPasswordEntered = this.state.password === "";
//     let user = {};

//     isUserNameEntered
//       ? (user = {
//           email: this.state.email,
//           password: isPasswordEntered ? this.state.password : "secret",
//           fullname: this.state.fistname + " " + this.state.lastname,
//           role: this.state.role,
//           departmentId: this.state.departmentId,
//           active: 1,
//           jobTitle: this.state.jobTitle,
//           startDate: this.state.startDate,
//           endDate: this.state.endDate,
//           userName: this.state.userName,
//           password: this.state.password,
//         })
//       : (user = {
//           email: this.state.email,
//           password: isPasswordEntered ? this.state.password : "secret",
//           fullname: this.state.fistname + " " + this.state.lastname,
//           role: this.state.role,
//           departmentId: this.state.departmentId,
//           active: 1,
//           jobTitle: this.state.jobTitle,
//           startDate: this.state.startDate,
//           endDate: this.state.endDate,
//         });

//     try {
//       // Creating user
//       const userResponse = await axios({
//         method: "post",
//         url: "/api/users",
//         data: user,
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });

//       const userId = userResponse.data.id;
//       const otherInfo = {
//         prevBankName: this.state.prevBankName,
//         prevBankAccNumber: this.state.prevBankAccNumber,
//         laptop: this.state.laptop,
//       };

//       const userPersonalInfo = {
//         dateOfBirth: this.state.dateOfBirth,
//         gender: this.state.gender,
//         maritalStatus: this.state.maritalStatus,
//         fatherName: this.state.fathername,
//         address: this.state.address,
//         city: this.state.city,
//         country: "Nepal",
//         mobile: this.state.mobile,
//         guardianPhone: this.state.phone,
//         citizenshipNumber: this.state.citizenshipNumber,
//         panNumber: this.state.panNumber,
//         otherInfo: otherInfo,
//         userId: userId,
//       };

//       // Creating personal information
//       await axios({
//         method: "post",
//         url: "/api/personalInformations",
//         data: userPersonalInfo,
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });

//       const userFinancialInfo = {
//         bankName: this.state.bankName,
//         accountName: this.state.accountName,
//         accountNumber: this.state.accountNumber,
//         userId: userId,
//       };

//       // Creating financial information
//       await axios({
//         method: "post",
//         url: "api/financialInformations",
//         data: userFinancialInfo,
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       });

//       this.setState({ completed: true });
//       window.scrollTo(0, 0);
//     } catch (err) {
//       this.setState({
//         hasError: true,
//         errMsg: err.response?.data?.message || "An error occurred",
//       });
//       window.scrollTo(0, 0);
//     }
//   };

//   pushDepartments = () => {
//     let items = [];
//     this.state.departments.map((dept, index) => {
//       items.push(
//         <option key={index} value={dept.id}>
//           {dept.departmentName}
//         </option>
//       );
//     });
//     return items;
//   };

//   render() {
//     return (
//       <Form onSubmit={this.onSubmit}>
//         <div className="row">
//           {this.state.hasError ? (
//             <Alert variant="danger" className="m-3" block>
//               {this.state.errMsg}
//             </Alert>
//           ) : this.state.completed ? (
//             <Alert variant="success" className="m-3" block>
//               Employee has been inserted.
//             </Alert>
//           ) : (
//             <></>
//           )}

//           {/* Main Card */}
//           <Card className="col-sm-12 main-card">
//             <Card.Header>
//               <b>Add Employee</b>
//             </Card.Header>
//             <Card.Body>
//               <div className="row">
//                 {/* Personal Details Card */}
//                 <div className="col-sm-6">
//                   <Card className="secondary-card">
//                     <Card.Header>Personal Details</Card.Header>
//                     <Card.Body>
//                       <Card.Text>
//                         <Form.Group controlId="formFirstName">
//                           <Form.Label className="text-muted required">
//                             First Name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter first Name"
//                             name="fistname"
//                             value={this.state.fistname}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>

//                         <Form.Group controlId="formLastName">
//                           <Form.Label className="text-muted required">
//                             Last Name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter last Name"
//                             name="lastname"
//                             value={this.state.lastname}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>

//                         <Form.Group controlId="formDateOfBirth">
//                           <Form.Label className="text-muted required">
//                             Date of birth
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter date of birth"
//                             name="dateOfBirth"
//                             value={this.state.dateOfBirth}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>

//                         <Form.Group controlId="formGender">
//                           <Form.Label className="text-muted required">
//                             Gender
//                           </Form.Label>
//                           <Form.Control
//                             as="select"
//                             value={this.state.gender}
//                             onChange={this.handleChange}
//                             name="gender"
//                             required
//                           >
//                             <option value="">Choose...</option>
//                             <option value="male">Male</option>
//                             <option value="female">Female</option>
//                           </Form.Control>
//                         </Form.Group>

//                         <Form.Group controlId="formMaritalStatus">
//                           <Form.Label className="text-muted required">
//                             Marital Status
//                           </Form.Label>
//                           <Form.Control
//                             as="select"
//                             value={this.state.maritalStatus}
//                             onChange={this.handleChange}
//                             name="maritalStatus"
//                             required
//                           >
//                             <option value="">Choose...</option>
//                             <option value="married">Married</option>
//                             <option value="single">Single</option>
//                           </Form.Control>
//                         </Form.Group>
//                         <Form.Group controlId="formCitizenshipNumber">
//                           <Form.Label className="text-muted required">
//                             Citizenship Number
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter citizenship number"
//                             name="citizenshipNumber"
//                             value={this.state.citizenshipNumber}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>

//                         <Form.Group controlId="formPanNumber">
//                           <Form.Label className="text-muted required">
//                             PAN number
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter PAN number"
//                             name="panNumber"
//                             value={this.state.panNumber}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </div>
//                 <div className="col-sm-6">
//                   <Card className="secondary-card">
//                     <Card.Header>Contact Details</Card.Header>
//                     <Card.Body>
//                       <Card.Text>
//                         <Form.Group controlId="formPhysicalAddress">
//                           <Form.Label className="text-muted required">
//                             Physical Address
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.address}
//                             onChange={this.handleChange}
//                             name="address"
//                             placeholder="Enter Address"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formCity">
//                           <Form.Label className="text-muted required">
//                             City
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.city}
//                             onChange={this.handleChange}
//                             name="city"
//                             placeholder="Enter City"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formMobile">
//                           <Form.Label className="text-muted required">
//                             Mobile
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.mobile}
//                             onChange={this.handleChange}
//                             name="mobile"
//                             placeholder="Enter Mobile"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formFatherName">
//                           <Form.Label className="text-muted required">
//                             Guardian's name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter Guardians's Name"
//                             name="fathername"
//                             value={this.state.fathername}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>

//                         <Form.Group controlId="formPhone">
//                           <Form.Label className="text-muted required">
//                             Guardian's phone
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             placeholder="Enter guardian's phone"
//                             name="phone"
//                             value={this.state.phone}
//                             onChange={this.handleChange}
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formEmployeeId">
//                           <Form.Label className="text-muted required">
//                             Employee Email
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.email}
//                             onChange={this.handleChange}
//                             name="email"
//                             placeholder="Enter email"
//                             required
//                           />
//                         </Form.Group>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-sm-6">
//                   <Card className="secondary-card">
//                     <Card.Header>Bank Information</Card.Header>
//                     <Card.Body>
//                       <Card.Text>
//                         <Form.Group controlId="formBankName">
//                           <Form.Label className="text-muted">
//                             Bank Name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.bankName}
//                             onChange={this.handleChange}
//                             name="bankName"
//                             placeholder="Enter Bank name"
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formAccountName">
//                           <Form.Label className="text-muted">
//                             Account Name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.accountName}
//                             onChange={this.handleChange}
//                             name="accountName"
//                             placeholder="Enter Account name"
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formAccountNumber">
//                           <Form.Label className="text-muted">
//                             Account Number
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.accountNumber}
//                             onChange={this.handleChange}
//                             name="accountNumber"
//                             placeholder="Enter Account number"
//                           />
//                         </Form.Group>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </div>
//                 <div className="col-sm-6">
//                   <Card className="secondary-card">
//                     <Card.Header>Official Status</Card.Header>
//                     <Card.Body>
//                       <Card.Text>
//                         <Form.Group controlId="formEmployeeId">
//                           <Form.Label className="text-muted">
//                             Username
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.userName}
//                             onChange={this.handleChange}
//                             name="userName"
//                             placeholder="Enter your username"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formEmployeeId">
//                           <Form.Label className="text-muted">
//                             Password
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.password}
//                             onChange={this.handleChange}
//                             name="password"
//                             placeholder="Enter your password"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formDepartment">
//                           <Form.Label className="text-muted required">
//                             Department
//                           </Form.Label>
//                           <Form.Control
//                             as="select"
//                             value={this.state.departmentId}
//                             onChange={this.handleChange}
//                             name="departmentId"
//                             required
//                           >
//                             <option value="" defaultValue>
//                               Choose...
//                             </option>
//                             {this.pushDepartments()}
//                           </Form.Control>
//                         </Form.Group>
//                         <Form.Group controlId="formRole">
//                           <Form.Label className="text-muted required">
//                             Role
//                           </Form.Label>
//                           <Form.Control
//                             as="select"
//                             value={this.state.role}
//                             onChange={this.handleChange}
//                             name="role"
//                             required
//                           >
//                             <option value="">Choose...</option>
//                             <option value="ROLE_ADMIN">Admin</option>
//                             <option value="ROLE_MANAGER">Manager</option>
//                             <option value="ROLE_EMPLOYEE">Employee</option>
//                           </Form.Control>
//                         </Form.Group>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </div>
//               </div>
//               <div className="row">
//                 <div className="col-sm-6">
//                   <Card className="secondary-card">
//                     <Card.Header>Job</Card.Header>
//                     <Card.Body>
//                       <Card.Text>
//                         <Form.Group controlId="formJobTitle">
//                           <Form.Label className="text-muted required">
//                             Job Title
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.jobTitle}
//                             onChange={this.handleChange}
//                             name="jobTitle"
//                             placeholder="Enter Job Title"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formStartDate">
//                           <Form.Label className="text-muted required">
//                             Start Date
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.startDate}
//                             onChange={this.handleChange}
//                             name="startDate"
//                             placeholder="Enter start date"
//                             required
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formEndDate">
//                           <Form.Label className="text-muted">
//                             End Date
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.endDate}
//                             onChange={this.handleChange}
//                             name="endDate"
//                             placeholder="Enter end date"
//                           />
//                         </Form.Group>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </div>
//                 <div className="col-sm-6">
//                   <Card className="secondary-card">
//                     <Card.Header>Extras</Card.Header>
//                     <Card.Body>
//                       <Card.Text>
//                         <Form.Group controlId="formPrevBankName">
//                           <Form.Label className="text-muted">
//                             Former Bank Name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.prevBankName}
//                             onChange={this.handleChange}
//                             name="prevBankName"
//                             placeholder="Enter prior bank name"
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formPrevBankAccName">
//                           <Form.Label className="text-muted">
//                             Former Bank Account Name
//                           </Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.prevBankAccNumber}
//                             onChange={this.handleChange}
//                             name="prevBankAccNumber"
//                             placeholder="Enter prior bank account number"
//                           />
//                         </Form.Group>
//                         <Form.Group controlId="formLaptop">
//                           <Form.Label className="text-muted">Laptop</Form.Label>
//                           <Form.Control
//                             type="text"
//                             value={this.state.laptop}
//                             onChange={this.handleChange}
//                             name="laptop"
//                             placeholder="Enter laptop information"
//                           />
//                         </Form.Group>
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </div>
//               </div>
//             </Card.Body>
//             <div style={{ display: "flex", justifyContent: "center" }}>
//               <Button
//                 variant="primary"
//                 type="submit"
//                 style={{ width: "240px", marginBottom: "20px" }}
//               >
//                 Submit
//               </Button>
//             </div>
//           </Card>
//         </div>
//       </Form>
//     );
//   }
// }

import React, { Component } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";

export default class EmployeeAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fistname: "",
      lastname: "",
      dateOfBirth: "",
      gender: "",
      maritalStatus: "",
      fathername: "",
      citizenshipNumber: "",
      bankName: "",
      accountName: "",
      accountNumber: "",
      panNumber: "",
      address: "",
      country: "",
      city: "",
      mobile: null,
      phone: null,
      email: "",
      userName: "",
      password: "",
      role: "",
      department: "",
      departmentId: null,
      startDate: "",
      endDate: "",
      departments: [],
      jobTitle: null,
      file: null,
      hasError: false,
      errMsg: "",
      completed: false,
      prevBankName: "",
      prevBankAccNumber: "",
      laptop: "",
    };
  }

  componentDidMount() {
    this.fetchDepartments();
    this.updateStateFromProps(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateStateFromProps(this.props);
    }
  }

  updateStateFromProps = (props) => {
    if (props?.location?.state?.departmentData) {
      const { city, email, fullName } = props.location.state.departmentData;

      const [firstname, lastname] = fullName.split(" ");

      this.setState({
        fistname: firstname || "",
        lastname: lastname || "",

        city: city || "",

        email: email || "",
      });
    }
  };

  fetchDepartments = () => {
    axios({
      method: "get",
      url: "/api/departments",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ departments: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  fileSelectedHandler = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  onSubmit = async (e) => {
    console.log(this.state);
    e.preventDefault();
    this.setState({ hasError: false, errorMsg: "", completed: false });

    const isUserNameEntered = this.state.userName !== "";
    const isPasswordEntered = this.state.password === "";
    let user = {};

    isUserNameEntered
      ? (user = {
          email: this.state.email,
          password: isPasswordEntered ? this.state.password : "secret",
          fullname: this.state.fistname + " " + this.state.lastname,
          role: this.state.role,
          departmentId: this.state.departmentId,
          active: 1,
          jobTitle: this.state.jobTitle,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          userName: this.state.userName,
          password: this.state.password,
        })
      : (user = {
          email: this.state.email,
          password: isPasswordEntered ? this.state.password : "secret",
          fullname: this.state.fistname + " " + this.state.lastname,
          role: this.state.role,
          departmentId: this.state.departmentId,
          active: 1,
          jobTitle: this.state.jobTitle,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        });

    try {
      // Creating user
      const userResponse = await axios({
        method: "post",
        url: "/api/users",
        data: user,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const userId = userResponse.data.id;
      const otherInfo = {
        prevBankName: this.state.prevBankName,
        prevBankAccNumber: this.state.prevBankAccNumber,
        laptop: this.state.laptop,
      };

      const userPersonalInfo = {
        dateOfBirth: this.state.dateOfBirth,
        gender: this.state.gender,
        maritalStatus: this.state.maritalStatus,
        fatherName: this.state.fathername,
        address: this.state.address,
        city: this.state.city,
        country: "Nepal",
        mobile: this.state.mobile,
        guardianPhone: this.state.phone,
        citizenshipNumber: this.state.citizenshipNumber,
        panNumber: this.state.panNumber,
        otherInfo: otherInfo,
        userId: userId,
      };

      // Creating personal information
      await axios({
        method: "post",
        url: "/api/personalInformations",
        data: userPersonalInfo,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const userFinancialInfo = {
        bankName: this.state.bankName,
        accountName: this.state.accountName,
        accountNumber: this.state.accountNumber,
        userId: userId,
      };

      // Creating financial information
      await axios({
        method: "post",
        url: "api/financialInformations",
        data: userFinancialInfo,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      this.setState({ completed: true });
      window.scrollTo(0, 0);
    } catch (err) {
      this.setState({
        hasError: true,
        errMsg: err.response?.data?.message || "An error occurred",
      });
      window.scrollTo(0, 0);
    }
  };

  pushDepartments = () => {
    let items = [];
    this.state.departments.map((dept, index) => {
      items.push(
        <option key={index} value={dept.id}>
          {dept.departmentName}
        </option>
      );
    });
    return items;
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <div className="row">
          {/* Display Alerts */}
          {this.state.hasError ? (
            <Alert variant="danger" className="m-3" block>
              {this.state.errMsg}
            </Alert>
          ) : this.state.completed ? (
            <Alert variant="success" className="m-3" block>
              Employee has been inserted.
            </Alert>
          ) : (
            <></>
          )}

          {/* Main Card */}
          {/* Main Card */}
          <Card className="col-sm-12 main-card">
            <Card.Header>
              <b>Add Employee</b>
            </Card.Header>
            <Card.Body>
              <div className="row">
                {/* Personal Details Card */}
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Personal Details</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formFirstName">
                          <Form.Label className="text-muted required">
                            First Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter first Name"
                            name="fistname"
                            value={this.state.fistname}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formLastName">
                          <Form.Label className="text-muted required">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter last Name"
                            name="lastname"
                            value={this.state.lastname}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formDateOfBirth">
                          <Form.Label className="text-muted required">
                            Date of birth
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter date of birth"
                            name="dateOfBirth"
                            value={this.state.dateOfBirth}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formGender">
                          <Form.Label className="text-muted required">
                            Gender
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.gender}
                            onChange={this.handleChange}
                            name="gender"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMaritalStatus">
                          <Form.Label className="text-muted required">
                            Marital Status
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.maritalStatus}
                            onChange={this.handleChange}
                            name="maritalStatus"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="married">Married</option>
                            <option value="single">Single</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formCitizenshipNumber">
                          <Form.Label className="text-muted required">
                            Citizenship Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter citizenship number"
                            name="citizenshipNumber"
                            value={this.state.citizenshipNumber}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formPanNumber">
                          <Form.Label className="text-muted required">
                            PAN number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter PAN number"
                            name="panNumber"
                            value={this.state.panNumber}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Contact Details</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formPhysicalAddress">
                          <Form.Label className="text-muted required">
                            Physical Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.address}
                            onChange={this.handleChange}
                            name="address"
                            placeholder="Enter Address"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formCity">
                          <Form.Label className="text-muted required">
                            City
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.city}
                            onChange={this.handleChange}
                            name="city"
                            placeholder="Enter City"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formMobile">
                          <Form.Label className="text-muted required">
                            Mobile
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.mobile}
                            onChange={this.handleChange}
                            name="mobile"
                            placeholder="Enter Mobile"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formFatherName">
                          <Form.Label className="text-muted required">
                            Guardian's name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Guardians's Name"
                            name="fathername"
                            value={this.state.fathername}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formPhone">
                          <Form.Label className="text-muted required">
                            Guardian's phone
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter guardian's phone"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formEmployeeId">
                          <Form.Label className="text-muted required">
                            Employee Email
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.email}
                            onChange={this.handleChange}
                            name="email"
                            placeholder="Enter email"
                            required
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Bank Information</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formBankName">
                          <Form.Label className="text-muted">
                            Bank Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.bankName}
                            onChange={this.handleChange}
                            name="bankName"
                            placeholder="Enter Bank name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formAccountName">
                          <Form.Label className="text-muted">
                            Account Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.accountName}
                            onChange={this.handleChange}
                            name="accountName"
                            placeholder="Enter Account name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formAccountNumber">
                          <Form.Label className="text-muted">
                            Account Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.accountNumber}
                            onChange={this.handleChange}
                            name="accountNumber"
                            placeholder="Enter Account number"
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Official Status</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formEmployeeId">
                          <Form.Label className="text-muted">
                            Username
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.userName}
                            onChange={this.handleChange}
                            name="userName"
                            placeholder="Enter your username"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formEmployeeId">
                          <Form.Label className="text-muted">
                            Password
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.password}
                            onChange={this.handleChange}
                            name="password"
                            placeholder="Enter your password"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formDepartment">
                          <Form.Label className="text-muted required">
                            Department
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.departmentId}
                            onChange={this.handleChange}
                            name="departmentId"
                            required
                          >
                            <option value="" defaultValue>
                              Choose...
                            </option>
                            {this.pushDepartments()}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formRole">
                          <Form.Label className="text-muted required">
                            Role
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.role}
                            onChange={this.handleChange}
                            name="role"
                            required
                          >
                            <option value="">Choose...</option>
                            <option value="ROLE_ADMIN">Admin</option>
                            <option value="ROLE_MANAGER">Manager</option>
                            <option value="ROLE_EMPLOYEE">Employee</option>
                          </Form.Control>
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Job</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formJobTitle">
                          <Form.Label className="text-muted required">
                            Job Title
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.jobTitle}
                            onChange={this.handleChange}
                            name="jobTitle"
                            placeholder="Enter Job Title"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formStartDate">
                          <Form.Label className="text-muted required">
                            Start Date
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.startDate}
                            onChange={this.handleChange}
                            name="startDate"
                            placeholder="Enter start date"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="formEndDate">
                          <Form.Label className="text-muted">
                            End Date
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.endDate}
                            onChange={this.handleChange}
                            name="endDate"
                            placeholder="Enter end date"
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6">
                  <Card className="secondary-card">
                    <Card.Header>Extras</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group controlId="formPrevBankName">
                          <Form.Label className="text-muted">
                            Former Bank Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.prevBankName}
                            onChange={this.handleChange}
                            name="prevBankName"
                            placeholder="Enter prior bank name"
                          />
                        </Form.Group>
                        <Form.Group controlId="formPrevBankAccName">
                          <Form.Label className="text-muted">
                            Former Bank Account Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.prevBankAccNumber}
                            onChange={this.handleChange}
                            name="prevBankAccNumber"
                            placeholder="Enter prior bank account number"
                          />
                        </Form.Group>
                        <Form.Group controlId="formLaptop">
                          <Form.Label className="text-muted">Laptop</Form.Label>
                          <Form.Control
                            type="text"
                            value={this.state.laptop}
                            onChange={this.handleChange}
                            name="laptop"
                            placeholder="Enter laptop information"
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Card.Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="primary"
                type="submit"
                style={{ width: "240px", marginBottom: "20px" }}
              >
                Submit
              </Button>
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}
