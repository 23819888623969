import React, { Component } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default class VacancyAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      description: "",
      employeeNumber: null,
      dueDate: "",
      salary: null,
      departmentId: null,
      departments: [],
      hasError: false,
      errMsg: "",
      completed: false,
    };
  }

  componentDidMount() {
    axios({
      method: "get",
      url: "/api/departments",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ departments: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  pushDepartments = () => {
    let items = [];
    this.state.departments.map((dept, index) => {
      items.push(
        <option key={index} value={dept.id}>
          {dept.departmentName}
        </option>
      );
    });
    return items;
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ hasError: false, errorMsg: "", completed: false });

    let vacancy = {
      title: this.state.title,
      description: this.state.description,
      employeeNumber: this.state.employeeNumber,
      dueDate: this.state.dueDate,
      salary: this.state.salary,
      departmentId: this.state.departmentId,
    };

    axios({
      method: "post",
      url: "/api/vacancies",
      data: { vacancy },
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => {
        this.setState({ completed: true });
      })
      .catch((err) => {
        this.setState({ hasError: true, errMsg: err.response.data.message });
        window.scrollTo(0, 0);
      });
  };

  render() {
    return (
      <Card className="mb-3 secondary-card">
        <Card.Header>
          <b>Add Vacancy</b>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <Form onSubmit={this.onSubmit}>
              <Form.Group controlId="formTitle">
                <Form.Label className="text-muted mb-2 required">
                  Title
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Vacancy Title"
                  name="title"
                  style={{ width: "50%" }}
                  value={this.state.title}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label className="text-muted mb-2 required">
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter Vacancy Description"
                  name="description"
                  style={{ width: "50%" }}
                  value={this.state.description}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmployeeNumber">
                <Form.Label className="text-muted mb-2 required">
                  Number of Employees
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Employee Number"
                  name="employeeNumber"
                  style={{ width: "50%" }}
                  value={this.state.employeeNumber}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDueDate">
                <Form.Label className="text-muted mb-2 required">
                  Due Date
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Vacancy due date"
                  name="dueDate"
                  style={{ width: "50%" }}
                  value={this.state.dueDate}
                  onChange={this.handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formSalary">
                <Form.Label className="text-muted mb-2">Salary</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Vacancy salary"
                  name="salary"
                  style={{ width: "50%" }}
                  value={this.state.salary}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formDepartment">
                <Form.Label className="text-muted required">
                  Department
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.departmentId}
                  onChange={this.handleChange}
                  name="departmentId"
                  required
                >
                  <option value="" defaultValue>
                    Choose...
                  </option>
                  {this.pushDepartments()}
                </Form.Control>
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2">
                Add
              </Button>
            </Form>
          </Card.Text>
        </Card.Body>
        {this.state.hasError ? (
          <Alert variant="danger" className="m-3" block>
            {this.state.errMsg}
          </Alert>
        ) : this.state.completed ? (
          <Redirect to="/vacancy-list" />
        ) : (
          <></>
        )}
      </Card>
    );
  }
}
