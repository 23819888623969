// import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
// import { Modal, Alert, Button } from "react-bootstrap";
// import axios from "axios";

// export default class DeleteModal extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       redirect: false,
//     };
//   }

//   onDelete = (event) => {
//     event.preventDefault();
//     if (this.props?.data?.fullName) {
//       axios({
//         method: "delete",
//         url: "api/users/" + this.props.data.id,
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       })
//         .then((res) => {
//           this.setState({ redirect: true });
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     } else {
//       axios({
//         method: "put",
//         url: "api/vacancies/" + this.props.data.id,
//         data: { status: "closed" },
//         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
//       })
//         .then((res) => {
//           this.setState({ redirect: true });
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   };

//   render() {
//     return (
//       <Modal
//         {...this.props}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         {this.state.redirect ? (
//           this.props?.data?.fullName ? (
//             <Redirect to="/employee-list" />
//           ) : (
//             <Redirect to="/vacancy-list" />
//           )
//         ) : (
//           <></>
//         )}
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">Warning</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {this.props?.data?.fullName
//             ? `Are you sure you want to delete Employee: ${this.props.data.fullName}`
//             : `Are you sure you want to close Vacancy: ${this.props.data.title}`}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="danger" onClick={this.onDelete}>
//             {this.props?.data?.fullName ? "Yes" : "Delete"}
//           </Button>
//           <Button onClick={this.props.onHide}>Close</Button>
//         </Modal.Footer>
//       </Modal>
//     );
//   }
// }

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

export default class DeleteModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
  }

  onDelete = (event) => {
    event.preventDefault();
    if (this.props?.data?.fullName) {
      axios({
        method: "delete",
        url: "api/users/" + this.props.data.id,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          this.setState({ redirect: true });
          this.props.refreshVacancies(); // Refresh the vacancies list
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios({
        method: "put",
        url: "api/vacancies/" + this.props.data.id,
        data: { status: "closed" },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          this.setState({ redirect: true });
          this.props.refreshVacancies(); // Refresh the vacancies list
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {this.state.redirect ? (
          this.props?.data?.fullName ? (
            <Redirect to="/employee-list" />
          ) : (
            <Redirect to="/vacancy-list" />
          )
        ) : (
          <></>
        )}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props?.data?.fullName
            ? `Are you sure you want to delete Employee: ${this.props.data.fullName}`
            : `Are you sure you want to close Vacancy: ${this.props.data.title}`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.onDelete}>
            {this.props?.data?.fullName ? "Delete" : "Yes"}
          </Button>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
