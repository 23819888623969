import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { Alert } from "react-bootstrap";

async function getAllEmployees() {
  try {
    const res = await axios({
      method: "get",
      url: "/api/users",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },

    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
}

const checkYearAndMonth = (year, month) => {
  const currentYear = new Date().getFullYear;
  const currentMonth = new Date().getMonth;

  if (year >= currentYear && month > currentMonth) {
    return false;
  }
  if (year < 2000 || year > 2100) {
    return false;
  }
  if (month > 12 || month < 1) {
    return false;
  }
  if (!month || !year) {
    return false;
  }
  return true;
};

export default class MassSalary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: 0,
      month: 0,
      users: [],
      selectAll: false,
      successMessage: null,
      errorMessage: null,
    };

    this.handleAttendanceChange = this.handleAttendanceChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }

  async componentDidMount() {
    try {
      const res = await axios({
        method: "get",
        url: "/api/users",
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },

      });

      let unmodUsers = res.data;
      //remove admins
      unmodUsers = unmodUsers.filter((user) => user.role !== "ROLE_ADMIN");

      const modUsers = unmodUsers.map((user) => {
        const userId = user.id;
        const departmentId = user.departmentId;
        const financial = user.user_financial_info;

        const modedSingleUser = {
          id: user.id,

          fullname: user.fullName,
          email: user.email,

          pay: false,
          amount: financial.salaryNet || 0,
          tax: 0,
          comment: "",
        };
        return modedSingleUser;
      });

      console.log(modUsers);
      this.setState({ users: modUsers });
    } catch (err) {
      console.log(err);
    }
  }

  handleAttendanceChange(employeeId, key, value) {
    this.setState((prevState) => ({
      users: prevState.users.map((user) =>
        user.id === employeeId ? { ...user, [key]: value } : user
      ),
    }));
  }

  handleSubmit() {
    const { year, month, users } = this.state;
    if (!checkYearAndMonth(year, month)) {
      this.setState({
        errorMessage: "Enter proper month and year please",
        successMessage: null,
      });
      return;
    }
    let newUsers = users.filter((user) => user.pay === true);
    const makePayments = async (users, year, month) => {
      const paymentPromises = users.map(async (user) => {
        // console.log(user);
        const userId = user.id;
        let payAmount = 0;
        if (Number(user.amount) - Number(user.tax) < 0) {
          payAmount = 0;
        } else {
          payAmount = Number(user.amount) - Number(user.tax);
        }
        const newPayment = {
          paymentType: "Cash",
          paymentMonth: moment(`${year}-${month}`, "YYYY-MM").format("YYYY-MM"),
          paymentDate: moment(new Date()).format("YYYY-MM-DD"),
          paymentFine: 0,
          paymentAmount: payAmount,
          comments: user.comment || "mass payments",
          userId: user.id,
        };

        try {
          const response = await axios({
            method: "post",
            url: "/api/payments/",
            data: newPayment,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          return response.data;
        } catch (error) {
          console.log(error);
          throw error;
        }
      });

      try {
        const results = await Promise.all(paymentPromises);
        console.log("All payments processed:", results);
        this.setState({
          successMessage: "All payments successfully processed",
          errorMessage: null,
        });
      } catch (error) {
        this.setState({
          errorMessage: "Payment invalid",
          successMessage: null,
        });
        console.error("Error in processing payments:", error);
      }
    };
    makePayments(newUsers, year, month);
  }

  handleSelectAll(event) {
    const { checked } = event.target;
    this.setState((prevState) => ({
      selectAll: checked,
      users: prevState.users.map((user) => ({
        ...user,
        pay: user.amount !== 0 ? checked : user.pay,
      })),
    }));
  }

  render() {
    const tableStyles = {
      width: "90%",
      margin: "auto",
      borderCollapse: "collapse",
    };

    const thStyles = {
      backgroundColor: "rgb(44, 177, 188)",
      color: "white",
      padding: "5px",
      textAlign: "center",
    };

    const tdStyles = {
      padding: "5px",
      textAlign: "center",
    };
    const inputStyles = {
      display: "flex",
      justifyContent: "center",
      gap: "5px",
      alignItems: "center",
      marginBottom: "10px",
    };

    const inputNumberStyles = {
      padding: "5px",
      borderRadius: "8px",
    };
    const buttonStyles = {
      backgroundColor: "rgb(44, 177, 188)",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "16px",
      marginTop: "15px",
    };

    return (
      <div className="container" style={{ padding: "20px" }}>
        <div
          className="date"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "15px 0",
            gap: "25px",
          }}
        >
          <label style={inputStyles}>
            Month:
            <input
              style={inputNumberStyles}
              type="number"
              name="month"
              value={this.state.month}
              onChange={(e) => this.setState({ month: e.target.value })}
            />
          </label>
          <label style={inputStyles}>
            Year:
            <input
              style={inputNumberStyles}
              type="number"
              name="year"
              value={this.state.year}
              onChange={(e) => this.setState({ year: e.target.value })}
            />
          </label>
        </div>
        {this.state.successMessage && (
          <div style={{ margin: "8px 0" }}>
            <Alert variant="success">{this.state.successMessage}</Alert>
          </div>
        )}

        {this.state.errorMessage && (
          <div style={{ margin: "8px 0" }}>
            <Alert variant="danger">{this.state.errorMessage}</Alert>
          </div>
        )}

        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <input
            type="checkbox"
            checked={this.state.selectAll}
            onChange={this.handleSelectAll}
          />
          <label>Select All</label>
        </div>
        <table style={tableStyles} border="1" cellPadding="10" cellSpacing="0">
          <thead>
            <tr>
              <th style={thStyles}>Full Name</th>
              <th style={thStyles}>Email</th>
              <th style={thStyles}>Pay</th>
              <th style={thStyles}>Salary</th>
              <th style={thStyles}>Deductions</th>
              <th style={thStyles}>Comment</th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map((student) => (
              <tr key={student.id}>
                <td style={tdStyles}>{student.fullname}</td>
                <td style={tdStyles}>{student.email}</td>
                <td style={tdStyles}>
                  <input
                    type="checkbox"
                    checked={student.pay}
                    disabled={student.amount === 0}
                    onChange={(e) =>
                      this.handleAttendanceChange(
                        student.id,
                        "pay",
                        e.target.checked
                      )
                    }
                  />
                </td>
                <td style={tdStyles}>
                  <input
                    type="number"
                    style={{
                      padding: "5px",
                      width: "90%",
                      borderRadius: "10px",
                    }}
                    value={student.amount}
                    disabled
                  />
                </td>
                <td style={tdStyles}>
                  <input
                    type="number"
                    style={{
                      padding: "5px",
                      width: "90%",
                      borderRadius: "10px",
                    }}
                    value={student.tax}
                    onChange={(e) =>
                      this.handleAttendanceChange(
                        student.id,
                        "tax",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td style={tdStyles}>
                  <input
                    type="text"
                    style={{
                      padding: "5px",
                      width: "90%",
                      borderRadius: "10px",
                    }}
                    value={student.comment}
                    onChange={(e) =>
                      this.handleAttendanceChange(
                        student.id,
                        "comment",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <button style={buttonStyles} onClick={this.handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    );
  }
}
